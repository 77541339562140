<template>
    <div id="courseware" class="courseware" :class="deviceInfo.mobile ? 'mobile-courseware' : ''">
        <div class="title">
            <div class="return-btn" @click="toBack">
                <van-icon name="arrow-left" />
                <span>返回</span>
            </div>
            <div class="course-title">{{ currentFile ? currentFile.name : '' }}</div>
        </div>
        <div class="courseware-content">
            <div class="preview">
                <template v-if="currentFile">
                    <div class="pdf" v-if="currentFile.fileType === 'pdf'">
                        <div class="mobile-pdf" v-if="deviceInfo.mobile">
                            <van-button @click="onOpenFile" color="#f0941d">查看文件</van-button>
                        </div>
                        <iframe :src="`${currentFile.fileUrl}#toolbar=0`" v-else-if="isShowPdf">
                            This browser does not support PDFs. Please download the PDF to view it:
                            <a :href="currentFile.fileUrl">Download PDF</a>
                        </iframe>
                    </div>
                    <div class="audio" v-else-if="currentFile.fileType === 'audio'">
                        <audio controls="controls" :src="currentFile.fileUrl" oncontextmenu="return false">
                            您的浏览器不支持 audio 标签。
                        </audio>
                    </div>
                    <div class="video" v-else-if="currentFile.fileType === 'video'">
                        <video
                            controls="controls"
                            :src="currentFile.fileUrl"
                            oncontextmenu="return false"
                            controlslist="nodownload"
                        >
                            您的浏览器不支持 video 标签。
                        </video>
                    </div>
                </template>
            </div>
            <div class="file-list">
                <div
                    class="file-item"
                    :class="currentFile && currentFile.id === item.id ? 'active-file-item' : ''"
                    v-for="item in handoutList"
                    :key="item.id"
                    @click="onPreviewFile(item)"
                >
                    <img class="icon-img" :src="iconMap[item.fileType]" />
                    <div>
                        <div class="file-name">{{ item.name }}</div>
                        <div class="file-type">
                            <span>{{ item.fileType.toUpperCase() }}</span>
                            <span v-if="!deviceInfo.mobile" @click.stop="ondownloadFile(item)" class="btn">
                                立即下载
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Watermark />
    </div>
</template>
<script>
import Watermark from '@/components/Watermark'
import { mapGetters } from 'vuex'
import { getHandout, getHandoutInfo } from '@/api/studyApi.js'
import { uploadAction } from '@/api/courseApi.js'
import { saveAs } from 'file-saver'

export default {
    name: 'courseware',
    components: { Watermark },
    data() {
        return {
            iconMap: {
                audio: 'https://res.jiaoshi.fun/resource/20231030/ee20f6380b2a413c88e6f44eab4ca5db.png',
                pdf: 'https://res.jiaoshi.fun/resource/20231030/0dea6638a9ff4849a8f688656595c5b1.png',
                video: 'https://res.jiaoshi.fun/resource/20231030/e717c878628d42818f0a2d4e8f17d523.png',
            },
            handoutList: [],
            currentFile: null,
            isShowPdf: false,
        }
    },
    computed: {
        ...mapGetters(['userInfo', 'deviceInfo']),
    },
    mounted() {
        this.getHandoutApi()
    },
    methods: {
        toBack() {
            this.$router.back()
        },
        onPreviewFile(item) {
            this.currentFile = item
            this.getHandoutInfoApi()
        },
        onOpenFile() {
            this.$dialog
                .alert({
                    message:
                        '本资料版权归本公司所有，仅限个人学习，严禁任何形式的传播和分享，一经发现，平台将依法保留追究权，情节严重者将承担法律责任。',
                })
                .then(() => {
                    window.location.href = this.currentFile.fileUrl
                })
        },
        ondownloadFile(item) {
            const { courseNo } = this.$route.params
            this.$dialog
                .alert({
                    message:
                        '本资料版权归本公司所有，仅限个人学习，严禁任何形式的传播和分享，一经发现，平台将依法保留追究权，情节严重者将承担法律责任。',
                })
                .then(() => {
                    getHandoutInfo({ courseNo, handoutId: item.id }).then((res) => {
                        saveAs(res.data.fileUrl, res.data.name)

                        // 上报下载动作
                        uploadAction({
                            action: 'DOWNLOAD_HANDOUT',
                            target: courseNo,
                            userId: this.userInfo.id,
                            extra: {
                                courseNo: courseNo,
                                handoutId: item.id,
                            },
                        })
                            .then(() => {})
                            .catch(() => {})
                    })
                })
        },
        getHandoutApi() {
            const { courseNo } = this.$route.params
            getHandout({ courseNo })
                .then((res) => {
                    if (res.data.length !== 0) {
                        this.handoutList = res.data
                        this.currentFile = res.data[0]
                        this.getHandoutInfoApi()
                    } else {
                        this.$toast('暂无课件')
                    }
                })
                .catch(() => {
                    this.$toast('暂无课件')
                })
        },
        getHandoutInfoApi() {
            const { courseNo } = this.$route.params
            this.isShowPdf = false
            getHandoutInfo({ courseNo, handoutId: this.currentFile.id }).then((res) => {
                this.currentFile = res.data
                this.isShowPdf = true
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.courseware {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: #37393d;
    .title {
        width: 100%;
        height: 54px;
        box-sizing: border-box;
        padding: 0 20px;
        background: #000000;
        position: relative;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        .return-btn {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #f0941d;
            line-height: 14px;
            margin-right: 20px;
            cursor: pointer;
            .van-icon {
                margin-right: 6px;
            }
        }
        .course-title {
            font-size: 14px;
            color: #ffffff;
            line-height: 14px;
            flex: 1;
        }
        .jiaozi-kejian {
            color: #df7e2f;
        }
    }
}
.courseware-content {
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    display: flex;
    height: 100%;
    .preview {
        flex-grow: 1;
        height: 100%;
        background: #000000;
        .pdf,
        .pdf iframe {
            width: 100%;
            height: 100%;
            display: block;
        }
        .audio {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .video {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            video {
                width: 100%;
            }
        }
    }
    .file-list {
        width: 320px;
        flex-shrink: 0;
        background: #37393d;
        height: 100%;
        padding: 12px;
        box-sizing: border-box;
        overflow-y: auto;
        .file-item {
            display: flex;
            margin-top: 16px;
        }
        .icon-img {
            flex-shrink: 0;
            width: 72px;
            height: 72px;
            background: none;
        }
        .file-item > div {
            flex-grow: 1;
            margin-left: 12;
            padding: 6px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .file-name {
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.6em;
            @include ov-ellipsiss(2);
        }
        .file-type {
            font-size: 12px;
            color: rgba($color: #ffffff, $alpha: 0.8);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn {
                width: 65px;
                height: 26px;
                border-radius: 3px;
                background: #f0941d;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-weight: 700;
            }
        }
        .active-file-item .file-name,
        .active-file-item .file-type {
            color: #f0941d;
        }
    }
}

// 移动端适配

.mobile-courseware {
    .courseware-content {
        flex-direction: column;
    }
    .preview {
        max-height: 400px;
        .pdf {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .mobile-pdf {
            .van-button {
                width: 180px;
                margin-bottom: 12px;
            }
        }
    }
    .file-list {
        width: 100%;
        height: 300px;
    }
}
</style>
